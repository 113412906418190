import React, {useEffect, useCallback} from "react";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {Grid} from "@mui/material";
import TopRightMenu from "./TopRightMenu";
import Link from "@mui/material/Link"
import Hidden from '@mui/material/Hidden';
import QNavListItems from "./QNavListItems";
import {QFlowLogoSVG} from "../../components/img/QFlowLogoSVG";
import {Link as RouterLink} from "react-router-dom";
import Button from "@mui/material/Button";
import {useAppContext} from "../../libs/appContextLib";
import MobileMarketingMenu from "../MarketingPages/MobileMarketingMenu";
import axios from "axios";
import {config} from "../../config";
import ProductMarketingMenuv2 from "../MarketingPages/ProductMarketingMenuv2";
import {debounce} from "lodash";
import {styled, useTheme} from '@mui/material/styles';
import {ButtonGroup} from '@mui/material';
import {useLocation} from "react-router";
import ScrollToStyleAppBar from "./ScrollToStyleAppBar";
// import ScrollToStyleAppBar from "./ScrollToStyleAppBar";


export default function QNavBar(props) {

    const location = useLocation();

    // const solutionsPopupState = usePopupState({ variant: 'popover', popupId: 'solutionsMenu' })
    const theme = useTheme();


    const {currUser} = useAppContext();
    const {isAuthenticated} = useAppContext();

    // const [marketingAnchorEl, setMarketingAnchorEl] = React.useState(null);
    // const [solutionsAnchorEl, setSolutionsAnchorEl] = React.useState(null);


    const handleMarketingMenuClose = (e) => {
        e.preventDefault();
        // setMarketingAnchorEl(null);
        props.handleMobileMarketingDrawerToggle(e)
    };

    const [accountList, setAccountList] = React.useState([])
    const [searchString, setSearchString] = React.useState()
    const [isSearchingAccounts, setIsSearchingAccounts] = React.useState(false)

    const handleSearchChange = (e, newVal) => {
        qDebounce(newVal)
    }

    const qDebounce = useCallback(
        debounce((value) => {
            setSearchString(value);
            // send the server request here
            getAccountListForSearch()
        }, 1000),
        []
    );

    const {dataStatus} = useAppContext()

    // const postConfigBuildComplete = get(dataStatus, 'processing_status.status_data_build.core.complete', false)


    const getAccountListForSearch = async () => {
        // searchString && searchString.toString().length > 0 &&
        setIsSearchingAccounts(true)
        isAuthenticated &&
        (await axios.get(`${config.REACT_APP_BACKEND_HOST}/accounts-list`,
            {
                headers: {
                    Authorization: currUser.authToken,
                },
                params: {
                    search_string: searchString && searchString.toString().length > 0 ? searchString : undefined
                }
            })
            .then(response => {

                const accountObj = []

                response.data.map((item, i) => {

                    accountObj.push({value: item.Id, name: item.Name})
                })

                setAccountList(accountObj)
                setIsSearchingAccounts(false)
            }).catch((error) => {
                console.log(error);
                setIsSearchingAccounts(false)
                const errorMsg = error.response && error.response.data.error
                console.log(errorMsg);
            }))
    }


    // useEffect(() => {
    //
    //     getAccountListForSearch()
    // }, [searchString])


    const openedMixin = (theme) => ({
        width: props.drawerWidth,
        whiteSpace: "nowrap",
        overflowX: 'hidden',
        [theme.breakpoints.up('md')]: {
            width: props.drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            background: "#FFF",
            color: "white",
            whiteSpace: "nowrap",
        }, [theme.breakpoints.down('md')]: {
            width: isAuthenticated ? 240 : "100%",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            color: "white",
            whiteSpace: "nowrap",
        },
        color: "white",
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('md')]: {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            whiteSpace: "nowrap",
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up("xs")]: {
                width: theme.spacing(9) + 1,
            },
            color: "white",
        },
        color: "white",
        [theme.breakpoints.down('md')]: {
            display: "none",
            overflowX: "hidden",
        },
    });

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({theme, open}) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: props.drawerWidth,
            width: `calc(100% - ${props.drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));


    const AuthenticatedDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
        ({theme, open}) => ({
            width: props.drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );

    const UnauthenticatedDrawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
        ({theme, open}) => ({
            // width: props.drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            color: "white",
            [theme.breakpoints.down('md')]: {
                width: 240,
                flexShrink: 0,
                whiteSpace: 'nowrap',
            },
            [theme.breakpoints.up('md')]: {
                width: 240,
                flexShrink: 0,
                whiteSpace: 'nowrap',
            },

            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );


    const localStateChatWidgetVisible = localStorage.getItem("chatWidgetVisibleLocal")

    const [chatWidgetVisible, setChatWidgetVisible] = React.useState(localStateChatWidgetVisible === 'true')


    useEffect(() => {
        setChatWidgetVisible(localStateChatWidgetVisible === 'true')

    }, [localStateChatWidgetVisible])

    const handleChatWidgetVisible = (e) => {
        e.preventDefault();

        if (!chatWidgetVisible) {
            setChatWidgetVisible(true)
            window.drift && window.drift.show()
            chatWidgetVisible !== undefined && localStorage.setItem("chatWidgetVisibleLocal", 'true')
        } else {
            setChatWidgetVisible(false)
            window.drift && window.drift.hide()
            chatWidgetVisible !== undefined && localStorage.setItem("chatWidgetVisibleLocal", 'false')
        }
    }


    return (
        <div
            // className={classes.root}
        >
            {/*<CssBaseline/>*/}
            {/*<Alert>test</Alert>*/}
            <ScrollToStyleAppBar>
                <AppBar
                    sx={{
                        // background: isAuthenticated ? "rgb(249, 249, 249)" : "#FFF",
                        background: isAuthenticated ? "rgb(249, 249, 249)" : "#FFF",
                        borderBottom: isAuthenticated ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
                    }}
                    elevation={0}
                    // position="static"
                >

                    <Toolbar
                        variant={isAuthenticated ? "dense" : undefined}
                    >

                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item xs={10} md={isAuthenticated ? 4 : 10} lg={isAuthenticated ? 4 : 9}>
                                <Grid
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    container
                                    spacing={0}>

                                    {isAuthenticated ?
                                        <Grid item>
                                            <IconButton
                                                color="primary"
                                                aria-label="open drawer"
                                                onClick={props.handleDrawerToggle}
                                                // onMouseOver={props.handleDrawerToggle}
                                                // onMouseOver={props.handleDrawerOpen}
                                                edge="start"
                                                // className={clsx(classes.menuButton, {
                                                //     [classes.hide]: props.open,
                                                // })}
                                                sx={{}}
                                                disableRipple
                                            >
                                                {props.open ? <ChevronLeftIcon color='primary'/> :
                                                    <ChevronRightIcon color='primary'/>}
                                                {/*<MenuIcon/>*/}
                                            </IconButton>
                                        </Grid>
                                        :

                                        <Hidden mdUp
                                            // implementation="css"
                                        >
                                            <Grid item>
                                                <IconButton
                                                    color="secondary"
                                                    aria-label="open drawer"
                                                    onClick={props.handleMobileMarketingDrawerToggle}
                                                    edge="start"
                                                    size="large">
                                                    {props.mobileMarketingMenuOpen ?
                                                        <ChevronLeftIcon color={"primary"} /> :
                                                         <ChevronRightIcon  color={"primary"}/>}
                                                </IconButton>
                                            </Grid>

                                        </Hidden>

                                    }

                                    {isAuthenticated ?
                                        <Hidden mdDown>
                                            <Grid item xs={9} sm={5} md={4} lg={3} xl={3}
                                                // className={classes.logoButton}
                                            >

                                                <Link component={RouterLink} to={"/"}>
                                        <span style={{
                                            float: 'left',
                                            marginRight: "10px",
                                            color: "#272931"
                                        }}>
                                            <QFlowLogoSVG width='7em' color={"#272931"}/>
                                        </span>{!isAuthenticated ?
                                                    <span style={{
                                                        float: 'left',
                                                        color: "#272931",
                                                        paddingTop: ' 1em'
                                                    }}>&nbsp;&trade;</span> : null}
                                                </Link>
                                            </Grid>
                                        </Hidden>
                                        :

                                        <Grid item
                                              xs={8} sm={4} md={2} lg={2} xl={2}
                                            // className={classes.logoButton}
                                        >
                                            <Link component={RouterLink} to={"/"}>
                                        <span style={{
                                            float: 'left',
                                            marginRight: "10px",
                                            color: "#272931"
                                        }}>
                                            <QFlowLogoSVG width='7em' color={"#272931" }/>
                                        </span>{
                                                <span style={{
                                                    float: 'left',
                                                    color: "#272931",
                                                    paddingTop: ' 1em'
                                                }}>&nbsp;&trade;</span>}
                                            </Link>
                                        </Grid>
                                    }


                                    {isAuthenticated ? null :
                                        <Grid item xs={12} md={8} lg={10} xl={7}>
                                            <Grid container alignContent='center' justifyContent='space-evenly'>
                                                <Hidden mdDown>
                                                    <Grid item><ProductMarketingMenuv2/>
                                                    </Grid>
                                                </Hidden>

                                                {/*<Hidden lgDown>*/}
                                                {/*    <Grid item>*/}
                                                {/*        <Button*/}
                                                {/*            color={useLightTheme ? "primary" : "secondary"}*/}
                                                {/*            edge="start"*/}
                                                {/*            aria-controls="finance" aria-haspopup="true"*/}
                                                {/*            href="/solutions/finance">*/}
                                                {/*            <b>For Finance</b>*/}
                                                {/*        </Button>*/}
                                                {/*    </Grid>*/}
                                                {/*</Hidden>*/}
                                                {/*<Hidden lgDown>*/}
                                                {/*    <Grid item>*/}
                                                {/*        <Button*/}
                                                {/*            color={useLightTheme ? "primary" : "secondary"}*/}
                                                {/*            edge="start"*/}
                                                {/*            aria-controls="revops" aria-haspopup="true"*/}
                                                {/*            href="/solutions/revenue-operations">*/}
                                                {/*            <b>For Revenue Teams</b>*/}
                                                {/*        </Button>*/}
                                                {/*    </Grid>*/}
                                                {/*</Hidden>*/}
                                                {/*/!*<Grid item>*!/*/}
                                                {/*/!*    <SolutionsMarketingMenu/>*!/*/}
                                                {/*/!*</Grid>*!/*/}
                                                <Hidden mdDown>
                                                    <Grid item>
                                                        <Button
                                                            color={"primary"}
                                                            edge="start"
                                                            aria-controls="blog" aria-haspopup="true"
                                                            href="https://blog.qflow.ai">
                                                            <b>Blog</b>
                                                        </Button>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdDown>
                                                    <Grid item>
                                                        <Button
                                                            color={"primary"}
                                                            edge="start"
                                                            aria-controls="pricing" aria-haspopup="true"
                                                            href="/pricing">
                                                            <b>Pricing</b>
                                                        </Button>
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdDown>
                                                    <Grid item>
                                                        <Button
                                                            color={"primary"}
                                                            edge="start"
                                                            aria-controls="about" aria-haspopup="true" href="/about">
                                                            <b>About</b>
                                                        </Button>
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item
                                  xs={2} md={isAuthenticated ? 8 : 1} lg={isAuthenticated ? 8 : 2}
                            >
                                {isAuthenticated && currUser ?

                                    <TopRightMenu
                                        handleChatWidgetVisible={handleChatWidgetVisible}
                                        chatWidgetVisible={chatWidgetVisible}
                                        stripePromise={props.stripePromise}
                                        handleClickedLogout={props.handleClickedLogout}
                                        currentCustomerId={props.currentCustomerId}
                                        currentUserLastName={props.currentUserLastName}
                                        currentUserRole={props.currentUserRole}
                                        currentCustomerCompanyName={props.currentCustomerCompanyName}

                                    />
                                    :
                                    <ButtonGroup>
                                        <Hidden lgDown>
                                            <Button variant="contained"
                                                    color={"primary"}
                                                    component="a"
                                                    href="/signup">
                                                <b>Get Started</b>
                                            </Button>
                                        </Hidden>
                                        <Hidden mdDown>
                                            <Button
                                                style={{minWidth: "80px"}}
                                                color={"primary"}
                                                href="/login"
                                                edge="start"
                                                variant={"outlined"}
                                            >
                                                Log in
                                            </Button>
                                        </Hidden>
                                    </ButtonGroup>
                                }
                                {
                                    !isAuthenticated &&
                                    <Hidden mdUp
                                        // implementation="css"
                                    >
                                        <UnauthenticatedDrawer
                                            variant="permanent"
                                            open={props.mobileMarketingMenuOpen}
                                        >
                                            <DrawerHeader>
                                                {/*<div style={{*/}
                                                {/*    zIndex: 1203,*/}
                                                {/*    display: 'flex',*/}
                                                {/*    alignItems: 'center',*/}
                                                {/*    justifyContent: 'flex-end',*/}
                                                {/*    backgroundColor: '#272931',*/}
                                                {/*}}>*/}
                                                <IconButton onClick={props.handleMobileMarketingDrawerToggle}
                                                            size="large">
                                                    {theme.direction === 'rtl' ? <ChevronRightIcon  color={"primary"}/> :
                                                        <ChevronLeftIcon color={"primary"}/>}
                                                </IconButton>
                                            </DrawerHeader>
                                            <Divider/>
                                            <List
                                                // disablePadding={true}
                                                style={{padding: '0px 0px 0px 0px'}}
                                            >

                                                <MobileMarketingMenu
                                                    handleMarketingMenuClose={handleMarketingMenuClose}
                                                />
                                            </List>
                                        </UnauthenticatedDrawer>
                                    </Hidden>
                                }
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                        {/*</Grid>*/}
                    </Toolbar>
                </AppBar>
            </ScrollToStyleAppBar>

            {
                isAuthenticated &&
                <Hidden mdUp
                    // implementation="css"
                >
                    <AuthenticatedDrawer
                        // onMouseOver={props.handleDrawerOpen}
                        variant="permanent"
                        // className={clsx(classes.drawer, {
                        //     [classes.drawerOpen]: props.open,
                        //     [classes.drawerClose]: !props.open,
                        // })}
                        // classes={{
                        //     paper: clsx({
                        //         [classes.drawerOpen]: props.open,
                        //         [classes.drawerClose]: !props.open,
                        //     }),
                        // }}
                        open={props.open}
                    >
                        {/*<div style={{*/}
                        {/*    zIndex: 1203,*/}
                        {/*    display: 'flex',*/}
                        {/*    alignItems: 'center',*/}
                        {/*    justifyContent: 'flex-end',*/}
                        {/*    backgroundColor: '#272931',*/}
                        {/*}}>*/}
                        <DrawerHeader>
                            <IconButton onClick={props.handleDrawerToggle} size="large">
                                {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon color='primary'/>}
                            </IconButton>
                        </DrawerHeader>
                        {/*<Divider/>*/}
                        <List
                            // disablePadding={true}
                            style={{padding: '0px 0px 0px 0px'}}
                        >
                            <QNavListItems
                                drawerOpen={props.open}
                            /></List>
                    </AuthenticatedDrawer>
                </Hidden>
            }


            {
                isAuthenticated &&
                <Hidden mdDown
                    // implementation="css"
                >
                    <AuthenticatedDrawer
                        variant="permanent"
                        // classes={{
                        //     paper: clsx({
                        //         [classes.drawerOpen]: props.open,
                        //         [classes.drawerClose]: !props.open,
                        //     }),
                        // }}
                        open={props.open}
                    >
                        <div style={{
                            zIndex: 1203,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <IconButton onClose={props.handleDrawerToggle} size="large">
                                {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon color='primary'/>}
                            </IconButton>
                        </div>
                        <Divider/>
                        <List>
                            <QNavListItems
                                drawerOpen={props.open}
                            /></List>
                    </AuthenticatedDrawer>
                </Hidden>
            }

        </div>
    );
}