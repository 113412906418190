import React from 'react';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import config from "./config";
import muiTheme from './Theme/muiTheme'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import ErrorFallback from "./components/ErrorBoundary";
import {ErrorBoundary} from 'react-error-boundary'
import TagManager from 'react-gtm-module'
import {HelmetProvider} from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import {
    QueryClient,
} from '@tanstack/react-query'
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
// Persist-related imports
import {PersistQueryClientProvider, removeOldestQuery} from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { compress, decompress } from 'lz-string'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    },
  },
})


// Setup localStorage persistor
const persister = createSyncStoragePersister({
  storage: window.localStorage,
    serialize: (data) => process.env.REACT_APP_STAGE === 'prod'  ? compress(JSON.stringify(data)) : JSON.stringify(data),
    deserialize: (data) => process.env.REACT_APP_STAGE === 'prod'  ? JSON.parse(decompress(data)) : JSON.parse(data),
    retry: removeOldestQuery,

})


const tagManagerArgs =
    {
        gtmId: 'GTM-T5QQWX3',
            dataLayerName: 'QDataLayer'
    }

process.env.REACT_APP_STAGE === 'prod' && TagManager.initialize(tagManagerArgs)

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_SRP_AUTH',
        oauth: {
            domain: config.cognito.oauth.domain,
            scope: config.cognito.oauth.scope,
            redirectSignIn: config.cognito.oauth.redirectSignIn,
            redirectSignOut: config.cognito.oauth.redirectSignOut,
            responseType: "code"
        },
        options: {
            // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
            AdvancedSecurityDataCollectionFlag: true
        }
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    },
    API: {
        endpoints: []
    }
})

window.addEventListener('error', (e) => {
  if (e.message && /Loading chunk \d+ failed/.test(e.message)) {
    console.error('Chunk Load Error: ', e.message);
    // Optionally display a user-friendly message
    // alert('A new version of the app is available. Please refresh the page.');
    window.location.reload(); // Force a reload to fetch new chunks
  }
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(

     <React.StrictMode>
           <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >


        <Router>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                    <HelmetProvider>
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <App />
                                </ErrorBoundary>
                    </HelmetProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Router>
                 <ReactQueryDevtools initialIsOpen={false}/>
           </PersistQueryClientProvider>
     </React.StrictMode>
);
